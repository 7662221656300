/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { alternateSortOptions } from '@/helper/sortOptions';
import { ArrowDown, ArrowLeft, ArrowRight, ArrowUp } from '../../icons/Arrows';
import Cancel from '../../icons/Cancel';

interface SelectedOption {
  value: SubOption[];
  label: string;
  code?: string;
  category?: string;
  type?: string;
  hasSubOptions?: boolean;
}

interface Option {
  value: string | SubOption[];
  label: string;
  code?: string;
  category?: string;
  type?: string;
  hasSubOptions?: boolean;
}

interface SubOption {
  value: string;
  label: string;
}

interface IconProps {
  isOpen: boolean;
}

const Icon: React.FC<IconProps> = ({ isOpen }) => (
  <div className="absolute bottom-[calc(50%-8px)] right-[16px]">
    {isOpen ? <ArrowUp /> : <ArrowDown />}
  </div>
);

interface AlternateCustomSelectProps {
  placeHolder: string;
  options: Option[];
  isSearchable?: boolean;
  value: string;
  name?: string;
  onSelect: (value: Option) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  error?: string | null;
  categorizeOptions?: boolean;
  isSorted?: boolean;
  priorityCategory?: string;
  dropHeight?: string;
  hideValue?: boolean;
  CategoryOptions?: Option[];
}

const AlternateCustomSelect: React.FC<AlternateCustomSelectProps> = ({
  placeHolder,
  options,
  isSearchable,
  onSelect,
  onChange,
  value,
  name,
  onBlur,
  error,
  categorizeOptions = false,
  isSorted = false,
  priorityCategory,
  dropHeight,
  hideValue = false,
  CategoryOptions = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [other, setOther] = useState(false);
  const [isSubOptionOpen, setIsSubOptionOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null,
  );
  const [searchValue, setSearchValue] = useState(``);
  const focusRef = useRef<HTMLDivElement>(null);
  const optionRef = useRef<HTMLDivElement>(null);
  const otherInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue(``);
  }, [isOpen]);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (focusRef.current && !focusRef.current.contains(e.target as Node)) {
        // setIsOpen(false);
      }
    };

    window.addEventListener(`click`, handler);
    return () => {
      window.removeEventListener(`click`, handler);
    };
  }, []);

  useEffect(() => {
    if (error && focusRef.current) {
      focusRef.current.scrollIntoView({ behavior: `smooth`, block: `center` });
    }
  }, [error]);

  useEffect(() => {
    setTimeout(() => {
      if (otherInputRef.current) {
        otherInputRef.current.focus();
        otherInputRef.current.setSelectionRange(0, 0);
      }
    }, 100);
  }, [other]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
    if (options.length === 1 && options[0].value === `N/A`) {
      // setSelectedValue(options[0]);
      onSelect(options[0]);
    }
  };

  const getDisplay = () => {
    const selectedOption = options.find((op) => {
      if (typeof op.value === `string`) {
        return op.value === value;
      }
      if (Array.isArray(op.value)) {
        return op.value.some((subOption) => subOption.value === value);
      }
      return false;
    });

    if (selectedOption && Array.isArray(selectedOption.value)) {
      const selectedSubOption = selectedOption.value.find(
        (subOption) => subOption.value === value,
      );
      if (selectedSubOption) {
        return selectedSubOption.label;
      }
    }

    return selectedOption?.label || placeHolder;
  };

  const onItemClick = (option: Option) => {
    if (option.hasSubOptions) {
      setIsSubOptionOpen(true);
      setSelectedOption({ ...option, value: option.value as SubOption[] });
    } else if (option.value === `other`) {
      setOther(true);
      onSelect({} as Option);
    } else {
      onSelect(option);
      setIsSubOptionOpen(false);
      setSearchValue(``);
    }
    setIsOpen(false);
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };

  const onSubOptionClick = (subOption: SubOption) => {
    onSelect({ value: subOption.value, label: subOption.label });
    setIsSubOptionOpen(false);
    setIsOpen(false);
    setSelectedOption(null);
  };

  const handleBackToMainOptions = () => {
    setSelectedOption(null);
    setIsSubOptionOpen(false);
    setIsOpen(true);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const getFilteredOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
  };

  const renderMainOptions = () => {
    const options = isSorted
      ? alternateSortOptions(getFilteredOptions())
      : getFilteredOptions();

    return options.map((option, index) => (
      <div
        onClick={() => onItemClick(option)}
        key={(option.value as string) + index}
        className={`p-[16px] cursor-pointer text-base text-navy-blue font-medium hover:bg-gray-100 border border-[#a3a1a8] ${
          option.hasSubOptions && `flex justify-between items-center`
        }`}
      >
        {option.label}
        {option.hasSubOptions && <ArrowRight />}
      </div>
    ));
  };

  const renderSubOptions = () => (
    <div>
      <div
        className="flex items-center p-[16px] gap-2 cursor-pointer text-base text-navy-blue font-bold hover:bg-gray-100 border border-b-[#a3a1a8] border-[#a3a1a8]"
        onClick={handleBackToMainOptions}
      >
        <ArrowLeft />
        <span className="font-bold">{selectedOption?.label}</span>
      </div>
      {selectedOption?.value.map((subOption, index) => (
        <div
          onClick={() => onSubOptionClick(subOption)}
          key={subOption.value + index}
          className="p-[16px] cursor-pointer text-base text-navy-blue font-medium hover:bg-gray-100 border border-b-[#a3a1a8]"
        >
          {subOption.label}
        </div>
      ))}
    </div>
  );

  return (
    <div
      className="relative"
      ref={focusRef}
      onBlur={onBlur}
      // onClick={handleToggle}
    >
      {isSearchable ? (
        <>
          {isOpen ? (
            <div className="relative">
              <input
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-blue ${
                  error ? ` border-red-500` : ` border-[#a3a1a8]`
                }`}
                placeholder="Search..."
                onChange={onSearch}
                value={searchValue}
              />
              <Icon isOpen={isOpen} />
            </div>
          ) : (
            <div className="relative">
              <input
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-blue ${
                  (value || options.find((eq) => eq.value === value)?.label) &&
                  `text-navy-blue`
                } ${error ? ` border-red-500` : ` border-[#a3a1a8]`}`}
                readOnly
                placeholder={getDisplay()}
                value={
                  options.find((eq) => eq.value === value)?.label
                    ? options.find((eq) => eq.value === value)?.label
                    : value || ``
                }
              />
              <Icon isOpen={isOpen} />
            </div>
          )}
        </>
      ) : (
        <>
          {other ? (
            <div className="relative">
              <input
                ref={otherInputRef}
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium outline-navy-blue  ${
                  error ? ` border-red-500` : ` border-[#a3a1a8]`
                }`}
                placeholder="Please type here..."
                value={value}
                name={name}
                onChange={onChangeInput}
              />
              <div
                onClick={() => {
                  // setSelectedValue(null);
                  setOther(false);
                }}
                className="absolute bottom-[calc(50%-8px)] right-[16px] cursor-pointer"
              >
                <Cancel />
              </div>
            </div>
          ) : (
            <div className="relative" onClick={handleToggle}>
              <input
                className={`w-full bg-inherit border rounded-lg p-[16px] text-base text-navy-blue font-medium cursor-pointer outline-navy-blue ${
                  (value || options.find((eq) => eq.value === value)?.label) &&
                  `select-input-class`
                } ${error ? ` border-red-500` : ` border-[#a3a1a8]`}`}
                placeholder={getDisplay()}
                value=""
                readOnly
              />
              <Icon isOpen={isOpen} />
            </div>
          )}
        </>
      )}

      {isOpen && (
        <div
          ref={optionRef}
          className={`absolute z-10 mt-1 w-full ${
            dropHeight || `max-h-44`
          } overflow-y-auto scroll-style border border-gray-300 rounded-md bg-white shadow-md z-10`}
        >
          {!selectedOption && renderMainOptions()}
        </div>
      )}

      {isSubOptionOpen && (
        <div
          ref={optionRef}
          className={`absolute z-10 mt-1 w-full ${
            dropHeight || `max-h-44`
          } overflow-y-auto scroll-style border border-gray-300 rounded-md bg-white shadow-md z-10`}
        >
          {selectedOption && renderSubOptions()}
        </div>
      )}
      {other && (
        <div className="text-[#999900] text-[14px] mt-[12px] flex flex-wrap gap-[2px]">
          <span>To return to viewing options please click the</span>
          <div className="flex">
            "<Cancel />"
          </div>
          <span>icon</span>
        </div>
      )}
      {error && <div className="text-red-500 text-base mt-[12px]">{error}</div>}
    </div>
  );
};

export default AlternateCustomSelect;
